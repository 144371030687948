import $ from 'jquery';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import {deviceIsMinLarge as deviceIsMinLargeMql} from '../utilities/media-queries';
import throttle from 'lodash/throttle';
import {getScrollbarWidth} from '../_utilities';
import {Aside} from './Aside';

export class Header {
  constructor(Ui) {
    this.Ui = Ui;
    this.$window = $(window);
    this.$body = $('body');
    this.$header = $('#js_header');
    this.isMinimized = false;
    this.$toggle = $('#js_header-menu-toggle');
    this.toggleIsInitialized = false;
    this.$menu = $('#js_header-menu');
    this.mobileMenuIsOpen = false;
    this.mobileMenuActiveClass = 'js_header-menu-active';
    this.mobileMenuFixedClass = 'js_header-menu-fixed';
    this.mobileMenuTransitionActiveClass = 'js_enable-transition';
    this.$searchForm = $('#js_header-search-form');
    this.Aside = null;

    deviceIsMinLargeMql.addListener(this.initMobileToggle.bind(this));
    this.initMobileToggle(deviceIsMinLargeMql);

    deviceIsMinLargeMql.addListener(this.initMobileMenu.bind(this));
    this.initMobileMenu(deviceIsMinLargeMql);

    deviceIsMinLargeMql.addListener(this.initFlyoutMenu.bind(this));
    this.initFlyoutMenu(deviceIsMinLargeMql);

    deviceIsMinLargeMql.addListener(this.initSearch.bind(this));
    this.initSearch(deviceIsMinLargeMql);

    this.initMetaLoginMenu();

    deviceIsMinLargeMql.addListener(this.initFixedHeader.bind(this));
    this.initFixedHeader(deviceIsMinLargeMql);

    deviceIsMinLargeMql.addListener(this.initMobileContactOffCanvas.bind(this));
    this.initMobileContactOffCanvas(deviceIsMinLargeMql);
  }

  /**
   * @param {object} deviceIsMinLarge MediaQueryList
   */
  initMobileToggle(deviceIsMinLarge) {
    if (this.toggleIsInitialized === false) {
      // Show button when it's possible to click
      this.$toggle.prop('disabled', false);
      this.toggleIsInitialized = true;
    }
    if (deviceIsMinLarge.matches === false) {
      // Init
      this.$toggle.on('click.Header.initMobileToggle', () => {
        if (this.$body.hasClass(this.mobileMenuActiveClass) === false) {
          // Open mobile menu
          const scrollbarWidth = getScrollbarWidth();
          this.mobileMenuIsOpen = true;

          if (this.Aside !== null) {
            this.Aside.hide();
          }
          this.$body.addClass(this.mobileMenuActiveClass);
          this.$body.css('padding-right', scrollbarWidth);
          this.$header.css('padding-right', scrollbarWidth);
          this.$toggle.attr('aria-expanded', true);
          this.Ui.showBackdrop();
          setTimeout(() => {
            // Allow optional scrolling within the menu
            this.$body.addClass(this.mobileMenuFixedClass);
          }, 300);
        } else {
          this.closeMobileMenu();
        }
      });
      setTimeout(() => {
        // Prevent menu from visibly flying around
        this.$menu.addClass(this.mobileMenuTransitionActiveClass);
      }, 200);
      this.Ui.$backdrop.on('click.Header.initMobileToggle', () => {
        this.closeMobileMenu();
      });
    } else {
      // Remove
      this.closeMobileMenu();
      setTimeout(() => {
        // Prevent menu from visibly flying around
        this.$menu.removeClass(this.mobileMenuTransitionActiveClass);
      }, 200);
      this.$toggle.off('click.Header.initMobileToggle');
      this.Ui.$backdrop.off('click.Header.initMobileToggle');
    }
  }

  closeMobileMenu() {
    if (this.mobileMenuIsOpen === false) {
      return;
    }
    this.mobileMenuIsOpen = false;
    this.Ui.hideBackdrop();
    this.$menu.removeClass(this.mobileMenuTransitionActiveClass);
    this.$body.removeClass(this.mobileMenuFixedClass);
    setTimeout(() => {
      this.$menu.addClass(this.mobileMenuTransitionActiveClass);
      this.$body.removeClass(this.mobileMenuActiveClass);
      this.$body.css('padding-right', '');
      this.$header.css('padding-right', '');
      this.$menu.css('height', '');
      this.$toggle.attr('aria-expanded', false);
    }, 25);
  }

  /**
   * @param {object} deviceIsMinLarge MediaQueryList
   */
  initMobileMenu(deviceIsMinLarge) {
    const $nextLevelButton = $('.js_navigation-main-level-1-button');
    const $backButton = $('.js_navigation-main-level-back-button');
    const level2ActiveClass = 'js_header-menu-level-2-active';
    const activeClass = 'js_active';

    if (deviceIsMinLarge.matches === false) {
      // Init
      $nextLevelButton.on('click.Header.initMobileMenu', (event) => {
        const $button = $(event.delegateTarget);

        this.$menu.addClass(level2ActiveClass);
        $button.attr('aria-expanded', true);
        $button.parent('.nav-item').addClass(activeClass);
        $button.siblings('.hs-navigation-main__level-wrapper').addClass(activeClass).focus();
      });
      $backButton.on('click.Header.initMobileMenu', (event) => {
        const $button = $(event.delegateTarget);
        const $navItem = $button.parents('.nav-item');

        this.$menu.removeClass(level2ActiveClass);
        $button.parent('.hs-navigation-main__level-wrapper').removeClass(activeClass);
        $navItem.removeClass(activeClass);
        $navItem.children('button').attr('aria-expanded', false).focus();
      });
    } else {
      // Remove
      $nextLevelButton.off('click.Header.initMobileMenu');
      $backButton.off('click.Header.initMobileMenu');
    }
  }

  /**
   * @param {object} deviceIsMinLarge MediaQueryList
   */
  initFlyoutMenu(deviceIsMinLarge) {
    const $flyoutButtons = $('.js_navigation-main-level-1-button');
    const $flyoutTriggers = $flyoutButtons.parent('.nav-item');
    const hoverClass = 'js_hover';

    if (deviceIsMinLarge.matches === true) {
      // Init

      // Set defaults
      $flyoutTriggers.each((index, element) => {
        const $trigger = $(element);

        $trigger.data('flyoutVisible', false);
        $trigger.data('mouseenterActive', false);
      });

      // Click (keyboard)
      $flyoutButtons.on('click.Header.initFlyoutMenu', (event) => {
        const $button = $(event.delegateTarget);
        const $trigger = $button.parent('.nav-item');

        if ($trigger.data('mouseenterActive') === true) {
          return;
        }

        if ($trigger.data('flyoutVisible') === true) {
          __stopShowFlyout($trigger);
          __hideFlyout($trigger, $button);
        } else {
          __stopHideFlyout($trigger);
          this.closeSearch();
          __showFlyout($trigger, $button);
        }
      });

      // Hover in
      $flyoutTriggers.on('mouseenter.Header.initFlyoutMenu', (event) => {
        const $trigger = $(event.delegateTarget);
        const $button = $trigger.children('button');

        $trigger.data('mouseenterActive', true);
        __stopHideFlyout($trigger);

        // Delay
        $trigger.data('timeoutShowFlyout', setTimeout(() => {
          this.closeSearch();
          __showFlyout($trigger, $button);
        }, 250));
      });

      // Hover out
      $flyoutTriggers.on('mouseleave.Header.initFlyoutMenu', (event) => {
        const $trigger = $(event.delegateTarget);
        const $button = $trigger.children('button');

        $trigger.data('mouseenterActive', false);
        __stopShowFlyout($trigger);

        // Delay
        $trigger.data('timeoutHideFlyout', setTimeout(() => {
          __hideFlyout($trigger, $button);
        }, 250));
      });
    } else {
      // Remove
      $flyoutButtons.off('click.Header.initFlyoutMenu');
      $flyoutTriggers.off('mouseenter.Header.initFlyoutMenu');
      $flyoutTriggers.off('mouseleave.Header.initFlyoutMenu');
    }

    function __showFlyout($trigger, $button) {
      $button.attr('aria-expanded', true);
      $trigger.data('flyoutVisible', true);

      $trigger.children('.hs-navigation-main__level-wrapper').show();

      // Delay to allow CSS animation
      $trigger.data('timeoutShowFlyoutAnimation', setTimeout(() => {
        $trigger.addClass(hoverClass);
      }, 20));
    }

    function __stopShowFlyout($trigger) {
      clearTimeout($trigger.data('timeoutShowFlyout'));
      clearTimeout($trigger.data('timeoutShowFlyoutAnimation'));
    }

    function __hideFlyout($trigger, $button) {
      $button.attr('aria-expanded', false);
      $trigger.data('flyoutVisible', false);

      $trigger.removeClass(hoverClass);

      // Delay to allow CSS animation
      $trigger.data('timeoutHideFlyoutAnimation', setTimeout(() => {
        // Explicit not .hide() in case of switching to mobile
        $trigger.find('.hs-navigation-main__level-wrapper').css('display', '');
      }, 250));
    }

    function __stopHideFlyout($trigger) {
      clearTimeout($trigger.data('timeoutHideFlyout'));
      clearTimeout($trigger.data('timeoutHideFlyoutAnimation'));
    }
  }

  /**
   * @param {object} deviceIsMinLarge MediaQueryList
   */
  initSearch(deviceIsMinLarge) {
    const $search = this.$searchForm.parent('.hs-header-search');
    const $searchFormInput = this.$searchForm.find('input').first();
    let isSearchNotFocused = true;

    if (deviceIsMinLarge.matches === true) {
      // Init
      $search.on('focusin.Header.initSearch', (event) => {
        isSearchNotFocused = false;
        // avoid infinite loops
        if (event.originalEvent.srcElement !== $searchFormInput.get(0)) {
          // wait until $search has focus and before $search closes
          setTimeout(() => {
            // avoid infinite loop
            $searchFormInput.focus();
          }, 200);
        }

      });
      $search.on('focusout.Header.initSearch', () => {
        isSearchNotFocused = true;
        // Timeout to allow other events to be fired
        setTimeout(() => {
          if (isSearchNotFocused) {
            this.closeSearch();
          }
        }, 250);
      });
    } else {
      // Remove
      $search.off('focusin.Header.initSearch');
      $search.off('focusout.Header.initSearch');
      this.closeSearch();
    }
  }

  closeSearch() {
    this.$searchForm.collapse('hide');
  }

  initMetaLoginMenu() {
    const self = this;
    const $metaLoginMenu = $('#hs-navigation-meta-login');
    const $dropdown = $metaLoginMenu.find('.dropdown');
    const $dropdownToggle = $dropdown.find('.hs-dropdown-toggle');
    const $dropdownMenu = $dropdown.find('.dropdown-menu');
    const dropdownMenuActiveClass = 'js_dropdown-menu-active';

    if ($dropdown.length < 1) {
      // Nothing to do
      return;
    }

    deviceIsMinLargeMql.addListener(handleViewportChanges);
    handleViewportChanges(deviceIsMinLargeMql);

    $dropdown
      .on('show.bs.dropdown', (event) => {
        this.closeMobileMenu();
        // Disable scrolling
        this.$body.addClass(dropdownMenuActiveClass);

        const scrollbarWidth = getScrollbarWidth();
        this.$body.css('padding-right', scrollbarWidth);
        this.$header.css('padding-right', scrollbarWidth);
      })
      .on('hide.bs.dropdown', (event) => {
        // Re-enable scrolling
        this.$body.removeClass(dropdownMenuActiveClass);
        this.$body.css('padding-right', '');
        this.$header.css('padding-right', '');
      })
      .on('hidden.bs.dropdown', () => {
        // Close all collapse container
        $dropdownMenu.find('.collapse').collapse('hide');
      })
      .on('click', '[data-toggle="collapse"]', (event) => {
        // Prevent dropdown from closing
        event.preventDefault();
        event.stopPropagation();

        // Trigger collapse manually
        const $toggle = $(event.currentTarget);
        const $collapseTarget = $($toggle.data('target'));
        $collapseTarget.collapse('toggle');
      });

    /**
     * @param {object} deviceIsMinLarge MediaQueryList
     */
    function handleViewportChanges(deviceIsMinLarge) {
      if (deviceIsMinLarge.matches === true) {
        moveDropdownToMeta();
        $dropdownMenu.css('top', $dropdownToggle.outerHeight());
        $dropdown
          .on('mouseenter', () => {
            $dropdownToggle.dropdown('show');
          })
          .on('mouseleave', () => {
            $dropdownToggle.dropdown('hide');
          });
      } else {
        moveDropdownToHeader();
        $dropdownMenu.css('top', Header.getCalculatedHeaderHeight());
        $dropdown
          .off('mouseenter')
          .off('mouseleave');
      }
    }

    function moveDropdownToMeta() {
      $dropdown.appendTo($metaLoginMenu);
    }

    function moveDropdownToHeader() {
      $dropdown.appendTo(self.$header);
    }

    $dropdown.find('.nav-item--has-children').each((index, navItem) => {
      const $navItem = $(navItem);
      const $collapseContainer = $navItem.find('.collapse');
      const isNavItemActive = $navItem.hasClass('js_nav-item--active');

      // Show children for current active nav item
      if (isNavItemActive) {
        $collapseContainer.collapse('show');
      }
    });
  }

  /**
   * @param {object} deviceIsMinLarge MediaQueryList
   */
  initFixedHeader(deviceIsMinLarge) {
    this.$body.addClass('js_header-fixed');
    // Set Bootstrap default class to allow modal.js
    // fix padding when removing scrollbar
    this.$header.addClass('fixed-top');
    this.$body.css('padding-top', this.$header.height());
  }

  /**
   * @param {object} deviceIsMinLarge MediaQueryList
   */
  initMobileContactOffCanvas(deviceIsMinLarge) {
    if (deviceIsMinLarge.matches === false) {
      // Init
      this.Aside = new Aside(this.Ui, $('#js_header-aside-contact-button'));
    } else {
      // Remove
      if (this.Aside !== null) {
        this.Aside.destroy();
        this.Aside = null;
      }
    }
  }

  /**
   * @return {number} Height in number, 0 if no header is available
   */
  static getCalculatedHeaderHeight() {
    const $header = $('#js_header');
    return parseInt($header.outerHeight()) + parseInt($header.css('margin-top'));
  }
}
