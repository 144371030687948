import $ from 'jquery';
import {Ui} from './Ui';
import {initGoogleTagManager} from './modules/GoogleTagManager';
import {initFormTracking} from './modules/FormTracking';
import {initEkomiWidget} from './modules/EkomiWidget';

/*
 * We load objectFitImages() in head-critical.js, so this workaround ensures
 * it's initialized before calling.
 * @see https://github.com/bfred-it/object-fit-images
 */
const maximalTries = 20; // 10 seconds
let currentTry = 0;
applyObjectFitPolyfill();

function applyObjectFitPolyfill() {
  currentTry += 1;
  if (typeof objectFitImages !== 'undefined') {
    objectFitImages();
  } else {
    if (currentTry === maximalTries) {
      return;
    }
    setTimeout(applyObjectFitPolyfill, 500);
  }
}


/*
 * Initialize UI
 */
new Ui();

/*
 * Initialize responsive iframe
 */
(async () => {
  const $iframe = $('.js_hs-iframe');
  if ($iframe.length) {
    const {IframeResizer} = await import(/* webpackChunkName: "IframeResizer" */ './modules/IframeResizer');
    const isAutoResize = $iframe.data('autoResize') === 1;
    new IframeResizer(isAutoResize);
  }
})();

/*
 * FE Login
 */
(async () => {
  const $loginForm = $('.js_hs-FeLogin-loginForm');
  const $logoutForm = $('.js_hs-FeLogin-logoutForm');

  if ($loginForm.length || $logoutForm.length) {
    const {FeLogin} = await import(/* webpackChunkName: "FeLogin" */ './modules/FeLogin');
    new FeLogin({
      $loginForm: $loginForm,
      $logoutForm: $logoutForm,
    });
  }
})();

/*
 * Demo Downloads
 */
(async () => {
  const $demoDownloads = $('.js_hs-DemoDownload-item');
  if ($demoDownloads.length) {
    const {DemoDownloads} = await import(/* webpackChunkName: "DemoDownloads" */ './modules/DemoDownloads');
    new DemoDownloads({$selector: $demoDownloads});
  }
})();

/*
 * Job Applications
 */
(async () => {
  const $jobApplicationLogin = $('.js_hs-job-application-login');
  if ($jobApplicationLogin.length) {
    const {JobApplicationLogin} = await import(/* webpackChunkName: "JobApplicationLogin" */ './modules/JobApplicationLogin');
    new JobApplicationLogin({$selector: $jobApplicationLogin});
  }
})();

/*
 * Multiple File Upload
 */
(async () => {
  const $multipleFileUpload = $('.hs-form-multiple-file-upload');
  if ($multipleFileUpload.length) {
    await import(/* webpackChunkName: "EXT-media-upload" */ 't3ext/media_upload/Resources/Public/Build/media_upload.js');
    if (window.MediaUpload === undefined) {
      window.MediaUpload = {
        instances: []
      };
    }
    MediaUpload.instances.push(window.hsMediaUploadInstance);

    // Remove previously uploaded files (from mu:widget.showUploaded) when selecting new files
    $multipleFileUpload.change(function(e) {
      // srcElement does not exist at runtime and we have no callbacks to register the eventHandler directly
      if (e.originalEvent.srcElement.title === 'file input') {
        $multipleFileUpload.find('.js-show-uploaded-list').remove();
      }
    });

    // Remove uploaded files if an error occurred
    if ($multipleFileUpload.next('.error').length) {
      $multipleFileUpload.find('input[name^="tx_mediaupload_pi1[uploadedFiles]"]').attr('value', '');
      $multipleFileUpload.find('.js-show-uploaded-list').remove();
    }
  }
})();

/*
 * PriceWizard
 */
(async () => {
  const $priceWizard = $('.js_hs-PriceWizard');
  if ($priceWizard.length) {
    const {PriceWizard} = await import(/* webpackChunkName: "PriceWizard" */ './modules/PriceWizard');
    $priceWizard.each((i, e) => {
      new PriceWizard({$priceWizardForm: $(e)});
    });
    PriceWizard.extendedTabHandling();
  }
})();

/*
 * Download Center Filter
 */
(async () => {
  const $downloadCenterFilter = $('.js-hs-DownloadCenterFilter');
  if ($downloadCenterFilter.length) {
    const {DownloadCenterFilter} = await import(/* webpackChunkName: "DownloadCenterFilter" */ './modules/DownloadCenterFilter');
    new DownloadCenterFilter({$selector: $downloadCenterFilter});
  }
})();

/*
 * Referral Link Form
 */
(async () => {
  const referralLinkSelector = '.js-hs-referralLink';
  if ($(referralLinkSelector).length) {
    const {ReferralLink} = await import(/* webpackChunkName: "ReferralLink" */ './modules/ReferralLink');
    new ReferralLink({selector: referralLinkSelector});
  }
})();

/*
 * Sliders
 */
(async () => {
  const $sliders = $('.swiper-container');
  if ($sliders.length) {
    const {Sliders} = await import(/* webpackChunkName: "Sliders" */ './modules/Sliders');
    new Sliders();
  }
})();

/*
 * Reference Collection
 */
(async () => {
  const $referenceCollection = $('.hs-collection-reference');
  if ($referenceCollection.length) {
    import(/* webpackChunkName: "ReferenceCollection" */ './modules/ReferenceCollection');
  }
})();

/*
 * Collapse Content Element
 */
(async () => {
  const $collapseContent = $('.hs-collapse-content-item');
  if ($collapseContent.length) {
    const {CollapseContent} = await import(/* webpackChunkName: "CollapseContent" */ './modules/CollapseContent');
    new CollapseContent({});
  }
})();
// /*
//  * News AJAX pagination
//  * TODO: Consider own implementation with history.pushState()
//  */
// const $newsWithPagination = $('.js_hs-ext-news-pagination .page-navigation');
// if ($newsWithPagination.length) {
//   import(/* webpackChunkName: "EXT-news-AjaxPagination" */ 't3ext/news/Resources/Public/JavaScript/AjaxPagination');
// }

/*
 * Tracking
 */
initGoogleTagManager();
initFormTracking();
initEkomiWidget();
