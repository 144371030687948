export const gridBreakpoints = {
  xs: 0,
  sm: 540,
  md: 768,
  lg: 992,
};

export const gridGutterWidth = 30;

export const deviceIsMinLarge = window.matchMedia(`only screen and (min-width: ${gridBreakpoints.lg}px)`);
