export class OffCanvas {
  constructor() {
    this.$toggle = $('');
    this.$self = $('');
    this.$close = $('');

    return this;
  }

  init() {
    this.attachEventHandler();
    this.initSelectors();
  }

  initSelectors() {
    this.$tabbables = this.$self.find('button, a');
    this.$tabbableFirst = this.$tabbables.first();
    this.$tabbableLast = this.$tabbables.last();
  }

  attachEventHandler() {
    this.$toggle.on('click.OffCanvas', (event) => {
      event.preventDefault();
      this.show();

      this.$self.on(
          'keydown.OffCanvas',
          _handleTabBackwardEvent.bind(this)
      );
      this.$self.on(
          'keydown.OffCanvas',
          _handleTabForwardEvent.bind(this)
      );
      this.$tabbableFirst.on(
          'keydown.OffCanvas',
          _handleTabBackwardEvent.bind(this)
      );
      this.$tabbableLast.on(
          'keydown.OffCanvas',
          _handleTabForwardEvent.bind(this)
      );

      this.$close.on('click.OffCanvas', () => {
        this.hide();
        this.removeOpenOffCanvasEventHandlers();
      });
    });
  }

  removeAllEventHandlers() {
    this.$toggle.off('click.OffCanvas');
    this.removeOpenOffCanvasEventHandlers();
  }

  removeOpenOffCanvasEventHandlers() {
    this.removeSelfEventHandler();
    this.$tabbableFirst.off('keydown.OffCanvas');
    this.$tabbableLast.off('keydown.OffCanvas');
    this.$close.off('click.OffCanvas');
  }

  removeSelfEventHandler() {
    this.$self.off('keydown.OffCanvas');
  }

  show() {
    this.$self.addClass('js_show');
    this.$self.attr('aria-hidden', false);
    this.$toggle.attr('aria-expanded', true);
    this.$self.focus();
  }

  hide() {
    this.$self.removeClass('js_show');
    this.$self.attr('aria-hidden', true);
    this.$toggle.attr('aria-expanded', false);
    this.$toggle.focus();
  }
}

/*
 * Private methods
 */
function _handleTabBackwardEvent(event) {
  if (event.shiftKey && event.keyCode === 9) {
    event.preventDefault();
    this.$tabbableLast.focus();

    if (event.target === this.$self[0]) {
      this.removeSelfEventHandler();
    }
  }
}

function _handleTabForwardEvent(event) {
  if (!event.shiftKey && event.keyCode === 9) {
    event.preventDefault();
    this.$tabbableFirst.focus();

    if (event.target === this.$self[0]) {
      this.removeSelfEventHandler();
    }
  }
}
