import Cookies from 'js-cookie';
import {getUrlParameter} from '../_utilities';

export function initFormTracking() {
  trackFormSubmit();
  storeReferredBy();
  storeReferrer();
  storeTrackingParams();
}

const storeTrackingParams = () => {

  const LIFETIME = 90; // days
  const COOKIE_NAME_TRACKING = 'hs_sea';

  const trackParam = (type, value) => {

    if (!value) {
      return;
    }

    const indexOfValue = tracking[type].findIndex(item => item.value === value);
    if (indexOfValue !== -1) {
      // remove value if already exists
      tracking[type].splice(indexOfValue, 1);
    }

    // add value
    tracking[type].push({value: value, date: Date.now()})
  }

  const deleteOutdatedTracking = () => {

    const lifeTimeInMs = 1000 * 60 * 60 * 24 * LIFETIME;

    for (let type in tracking) {
      if (tracking.hasOwnProperty(type) && Array.isArray(tracking[type])) {
        tracking[type].forEach((item, itemIndex) => {
          if (Date.now() - lifeTimeInMs > item.date) {
            // delete trackingItem
            tracking[type].splice(itemIndex, 1);
          }
        });
      }
    }
  }

  const readTracking = () => {
    let tracking = {
      source : [],
      keyword : [],
      matchType : []
    };

    // try parse tracking from cookie
    const trackingCookie = Cookies.get(COOKIE_NAME_TRACKING);
    if (trackingCookie) {
      let trackingDecoded = null;
      try {
        trackingDecoded = window.atob(trackingCookie);
      } catch(e) {}
      if (trackingDecoded) {
        try {
          tracking = JSON.parse(trackingDecoded);
        } catch(e) {}
      }
    }
    return tracking;
  }

  const writeTracking = tracking => {
    Cookies.set(COOKIE_NAME_TRACKING, window.btoa(JSON.stringify(tracking)), { expires: LIFETIME });
  }

  let tracking = readTracking();
  deleteOutdatedTracking();

  trackParam('source', getUrlParameter('quelle'));
  trackParam('keyword', getUrlParameter('kw'));
  trackParam('matchType', getUrlParameter('mt'));

  // Track newsletter links
  if (getUrlParameter('utm_source') === 'Newsletter') {
    trackParam('source', 'newsletter');
    trackParam('keyword', getUrlParameter('utm_campaign'));
  }

  writeTracking(tracking);
}

const storeReferrer = () => {
  const COOKIE_NAME_REFERRER = 'hs_referrer';
  const COOKIE_NAME_SESSION_SOURCE = 'hs_session-source';
  const hsDomain = 'hamburger-software.de';
  let referrer;

  if (document.referrer.length) {
    try {
      referrer = new URL(document.referrer);
    } catch (e) {
      return;
    }

    let searchEngines = ['google', 'bing'];
    let sessionSource = 'referal';

    if (referrer.hostname.length && (referrer.hostname.indexOf(hsDomain) === -1)) {
      Cookies.set(COOKIE_NAME_REFERRER, referrer.hostname);

      // store additional source, see HSW-672 for business logic
      searchEngines.forEach(searchEngine => {
        if (referrer.hostname.indexOf(searchEngine) !== -1) {
          sessionSource = 'non-referal';
        }
      });
      if (sessionSource === 'non-referal' && !getUrlParameter('quelle')) {
        sessionSource = 'organic';
      }
      if (['organic', 'referal'].includes(sessionSource)) {
        Cookies.set(COOKIE_NAME_SESSION_SOURCE, sessionSource);
      } else {
        // already tracked with param quelle as microsoftads / googleads
        Cookies.remove(COOKIE_NAME_SESSION_SOURCE);
      }
    }
  } else {
    Cookies.set(COOKIE_NAME_SESSION_SOURCE, 'direct');
  }
}

const trackFormSubmit = () => {
  const COOKIE_NAME_FORM_SUBMIT = 'hs_form_submit';
  const cookieValue = Cookies.get(COOKIE_NAME_FORM_SUBMIT);
  const formSubmitted = cookieValue !== undefined;

  if (formSubmitted) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event' : cookieValue
    });

    Cookies.remove(COOKIE_NAME_FORM_SUBMIT);
  }
}

const storeReferredBy = () => {
  const COOKIE_NAME_REFERRED_BY = 'hs_referred-by';
  const referredBy = getUrlParameter('referredby') || '';
  if (referredBy) {
    Cookies.set(COOKIE_NAME_REFERRED_BY, referredBy)
  }
}
