/** Ekomi Widget im Footer */
export function initEkomiWidget() {
  (function(w) {
    const token = 'sf140529602a609ab32a4';
    const k = document.getElementsByClassName('ekomi-widget-' + token);
    for (let x = 0; x < k.length; x++) {
      registerWidget(w, token);
    }
  })(window);

  function registerWidget(w, token) {
    w['_ekomiWidgetsServerUrl'] = 'https://widgets.ekomi.com';
    w['_customerId'] = 140529;
    w['_language'] = 'de';
    if (typeof (w['_ekomiWidgetTokens']) !== 'undefined') {
      w['_ekomiWidgetTokens'][w['_ekomiWidgetTokens'].length] = token;
    } else {
      w['_ekomiWidgetTokens'] = new Array(token);
    }
    if (typeof (ekomiWidgetJs) == 'undefined') {
      let ekomiWidgetJs = true;
      const scr = document.createElement('script');
      scr.src = 'https://sw-assets.ekomiapps.de/static_resources/widget.js';
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(scr);
    }
  }
}
