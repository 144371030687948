/**
 * @param {string} name
 * @return {*|number|null}
 */
export function getUrlParameter(name) {
  const results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
  if (results == null) {
    return null;
  }
  return decodeURIComponent(results[1]) || 0;
}

/**
 * Fork from Bootstrap modal
 * @return {boolean}
 */
export function isBodyOverflowing() {
  const rect = document.body.getBoundingClientRect();
  return rect.left + rect.right < window.innerWidth;
}

/**
 * Fork from Bootstrap modal
 * @return {number}
 */
export function getScrollbarWidth() {
  const scrollDiv = document.createElement('div');
  scrollDiv.className = 'hs-scrollbar-measure';
  document.body.appendChild(scrollDiv);
  const scrollbarWidth = scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
}
