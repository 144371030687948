import $ from 'jquery';

export function initTables() {
  const $tables = $('table.contenttable');
  if ($tables.length) {
    $tables.each((i, table) => {
      setColLabelForDataCell(table);
    })
  }
}

function setColLabelForDataCell(table) {
  const $thead = $('thead', table);
  if ($thead.length) {
    // wrap each data-cell and prepend with corresponding col-label
    $('th', $thead).each((j, th) => {
      const colLabel = th.textContent || th.innerText || "";
      $('tbody tr', table).each((k, tr) => {
        const cell = $('td,th', tr).eq(j);
        if (colLabel && cell.get(0).nodeName === 'TD') {
          let labelId = 'label-' + j + '-' + k;
          cell.addClass('contenttable__labelledCell').attr('aria-labelledby', labelId);
          cell.children().wrapAll($('<div>').addClass('contenttable__cellContent'));
          cell.prepend($('<div>').addClass('contenttable__colLabel').attr('id', labelId).html(colLabel));
        }
      })
    })
  }
}

