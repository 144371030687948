import {OffCanvas} from '../modules/OffCanvas';
import {deviceIsMinLarge as deviceIsMinLargeMql} from '../utilities/media-queries';

export class Aside extends OffCanvas {
  constructor(Ui, $toggle) {
    super();
    this.Ui = Ui;
    this.$toggle = $toggle || $('#js_aside-contact-button');
    this.$self = $('#js_aside-contact');
    this.$close = $('#js_aside-contact-close');
    this.deviceIsMinLarge = deviceIsMinLargeMql;
    this.$asideButton = $('.js_aside-button');

    this.init();
    hideDemoDownloadOnNoneWindowsDevices.call(this);
  }

  attachEventHandler() {
    super.attachEventHandler();

    if (this.deviceIsMinLarge.matches === false) {
      this.$toggle.on('click.Aside', () => {
        this.Ui.$backdrop.on('click.Aside', () => {
          this.hide();
          this.removeOpenOffCanvasEventHandlers();
        });
      });
    }
  }

  removeOpenOffCanvasEventHandlers() {
    super.removeOpenOffCanvasEventHandlers();
    this.Ui.$backdrop.off('click.Aside');
  }

  show() {
    this.Ui.Header.closeMobileMenu();
    this.$asideButton.addClass('js_hide');
    super.show();
    if (this.deviceIsMinLarge.matches === false) {
      this.Ui.showBackdrop();
    }
  }

  hide() {
    this.$asideButton.removeClass('js_hide');
    super.hide();
    this.Ui.hideBackdrop();
  }

  destroy() {
    this.hide();
    this.removeAllEventHandlers();
  }
}

function hideDemoDownloadOnNoneWindowsDevices() {
  const isProbablyNotAWindowsOS = window.navigator.userAgent.indexOf('Windows') === -1;
  if (isProbablyNotAWindowsOS) {
    const demoDownloadTile = document.getElementById('hs-aside-demo-download');
    if (demoDownloadTile) {
      document.getElementById('hs-aside-demo-download').style.display = 'none';
    }
  }
}
