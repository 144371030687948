import $ from 'jquery';
import 'bootstrap/js/dist/collapse'; // Necessary for Accordions
import bsCustomFileInput from 'bs-custom-file-input'
import {Header} from './ui/Header';
import {Aside} from './ui/Aside';
import {
  deviceIsMinLarge as deviceIsMinLargeMql,
  gridBreakpoints,
} from './utilities/media-queries';
import {initTables} from './ui/Tables';

export class Ui {
  constructor() {
    this.$backdrop = $('#js_backdrop');
    this.Header = new Header(this);
    this.Aside = null;

    deviceIsMinLargeMql.addListener(this.initAside.bind(this));
    this.initAside(deviceIsMinLargeMql);
    this.initCollapseTextSwitch();
    this.initModals();
    this.handleEmbeddedVideo();
    this.enableSmoothScrollingForAnchorLinks();
    this.initFormAdditions();
    initTables();
    this.initCollapseSmartScroll();
    this.initCollapseDisableInViewport();
    this.initPrintPage();
  }

  /**
   * @param {object} deviceIsMinLarge MediaQueryList
   */
  initAside(deviceIsMinLarge) {
    if (deviceIsMinLarge.matches === true) {
      // Init
      this.Aside = new Aside(this);
    } else {
      // Destroy
      if (this.Aside !== null) {
        this.Aside.destroy();
        this.Aside = null;
      }
    }
  }

  /**
   * Toggles the button text when collapse is opened/closed
   *
   * Currently used within Employee Collection.
   */
  initCollapseTextSwitch() {
    const $buttonsWithTextSwitch = $('[data-hs-collapse-switch-text]');
    if ($buttonsWithTextSwitch.length) {
      $buttonsWithTextSwitch.each((index, button) => {
        const $button = $(button);
        const $collapseTarget = $($button.data('target'));

        $collapseTarget.on('show.bs.collapse hide.bs.collapse', (event) => {
          // Prevent event bubbling within nested collapse
          event.stopPropagation();

          const buttonText = $button.text().trim();
          const switchText = $button.data('hs-collapse-switch-text');

          // Switch text
          $button.text(switchText);
          $button.data('hs-collapse-switch-text', buttonText);
        });
      });
    }
  }

  showBackdrop() {
    this.$backdrop.addClass('show');
  }

  hideBackdrop() {
    this.$backdrop.removeClass('show');
  }

  handleEmbeddedVideo() {
    const $videos = $('[data-video-embedded]');
    const $buttons = $('.hs-video-thumbs-overlay');

    function setCookie(name, value, days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = "expires=" + date.toUTCString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }

    function getCookie(name) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }

    function transformVideos() {
      $videos.each((index, element) => {
        const $video = $(element);
        const videoUrl = $video.data('video-embedded');
        const $videoEmbed = $video.find('.embed-responsive-item');

        if (videoUrl.length < 1) {
          return;
        }

        $videoEmbed.attr('src', videoUrl);

        const $button = $video.find('button');
        if ($button.length) {
          $button.addClass('d-none');
        }
      });
    }

    if (getCookie('videosTransformed')) {
      transformVideos();
    }

    $buttons.each((index, button) => {
      $(button).on('click', () => {
        setCookie('videosTransformed', 'true', 7);
        transformVideos();
      });
    });
  }

  enableSmoothScrollingForAnchorLinks() {
    /* Selector explained
     * a[href*=\#] = all links containing "#"
     * :not([href=\#]) = not links containing only a "#"
     * :not([data-toggle]) = not Bootstrap Toggle links (Collapse, Tabs)
     */
    $(document).on('click', 'a[href*=\\#]:not([href=\\#],[data-toggle])', (event) => {
      const target = event.currentTarget;
      const current = window.location;
      const hashValue = event.currentTarget.hash;
      const targetUrl = `${target.protocol}//${target.host + target.pathname + target.search + hashValue}`;
      const currentUrl = `${current.protocol}//${current.host + current.pathname + current.search + hashValue}`;

      // Only accept target URLs if same as current + hash
      if (targetUrl !== currentUrl) {
        return;
      }

      event.preventDefault();

      const $target = $(hashValue);
      if ($target.length) {
        const targetOffset = $target.offset().top;
        const headerHeight = Header.getCalculatedHeaderHeight();

        $('html,body').animate({scrollTop: targetOffset - headerHeight}, 1000);

        // Only update history if URL has changed
        if (targetUrl === current.href) {
          return;
        }

        // Update URL and extend History
        if (typeof window.history.pushState !== 'undefined') {
          window.history.pushState({}, null, targetUrl);
        }
      }
    });
  }

  initFormAdditions() {
    /*
     * Initialize File Upload handling
     * @see https://getbootstrap.com/docs/4.3/components/forms/?#file-browser
     */
    bsCustomFileInput.init();
  }

  /**
   * Scroll to top of Collapse button when opening, if it's not visible
   */
  initCollapseSmartScroll() {
    const $buttons = $('[data-toggle="collapse"]');

    $buttons.each((index, button) => {
      const $button = $(button);
      const $collapseTarget = $($button.data('target'));

      $collapseTarget.on('shown.bs.collapse', (event) => {
        // Prevent event bubbling within nested collapse
        event.stopPropagation();

        const buttonOffset = $button.offset().top;
        const documentOffset = $(document).scrollTop();
        const headerHeight = Header.getCalculatedHeaderHeight();
        const targetOffset = buttonOffset - headerHeight;
        const isButtonTopOutOfViewport = targetOffset < documentOffset;

        if (isButtonTopOutOfViewport) {
          $('html,body').animate({
            scrollTop: targetOffset,
          }, 250);
        }
      });
    });
  }

  /**
   * Disables a collapse toggle button in a defined viewport and upwards
   *
   * The viewport is defined within a custom data attribute.
   * This only disables the toggle button. CSS has to be provided by the
   * element, so that to collapse is opened in the viewport.
   *
   * Currently used a option for Accordion within Price Wizard.
   */
  initCollapseDisableInViewport() {
    const $toggles = $('[data-toggle-disable-viewport]');

    if ($toggles.length) {
      $toggles.each((index, toggle) => {
        const $toggle = $(toggle);
        const disableViewport = $toggle.data('toggle-disable-viewport');

        if (gridBreakpoints.hasOwnProperty(disableViewport)) {
          const deviceIsInViewport = window.matchMedia(`only screen and (min-width: ${gridBreakpoints[disableViewport]}px)`);

          deviceIsInViewport.addListener(__handleViewport.bind(null, deviceIsInViewport, $toggle));
          __handleViewport(deviceIsInViewport, $toggle);
        }
      });
    }

    /**
     * @param {object} deviceIsInViewport MediaQueryList
     * @param {object} $toggle jQuery node of the collapse toggle
     */
    function __handleViewport(deviceIsInViewport, $toggle) {
      if (deviceIsInViewport.matches) {
        // Disable
        $toggle.attr('data-toggle', null);
      } else {
        // Enable
        $toggle.attr('data-toggle', 'collapse');
      }
    }
  }

  initPrintPage() {
    const $printer = $('.js_hs-print-page');

    $printer.find('button').on('click', () => {
      window.print();
    });
  }

  initModals() {
    (async () => {
      if ($('.modal').length) {
        await import(/* webpackChunkName: "Modals" */ './ui/Modals');
      }
    })();
  }
}
